.me-auto a{
  text-decoration: none;
  color: brown;
  display:block;
  padding: 0px 10px 0px 10px;
  box-shadow: 2px 2px #ccc;
  margin: 2px;
}
.me-auto a:hover{
  text-decoration: none;
  color: darkblue;
  display:block;
  padding: 0px 10px 0px 10px;
  box-shadow: 2px 2px #999;
  margin:2px;
}
.activemenu{
  color: darkblue;
  box-shadow: 2px 2px #999;
}
.predshead{
  font-size: 26px;
  font-weight: 700;
  color:#ccc;
  /* text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; */
}
p{
  font-family: 'Times New Roman', Times, serif;
  text-align: justify;
  font-size: 22px;
  color: #eee;
}
td{
  color:#ccc;
}
th{
  color:#ccc;
}
.notebook {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  background-color: rgb(208, 232, 252);
  background: linear-gradient(to bottom, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.8) 100%), url(./background.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color:#ccc;
  padding: 20px;
  margin-top: 10px;
  /* background: linear-gradient(175deg, #B69180, #946947); #C9A8A1 */
  min-height: 100vh;
  min-width:85vw;
  box-shadow: 1px -1px 5px #555;
  border-top: 1px solid #946947;
}

.notebook:after {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0px;
    height: 4px;
    width: 100%;
    background-size: 3px 1px;
    background-image: -webkit-radial-gradient(40% 100%, circle, transparent 20%, #946947 35%);
    background-image: -moz-radial-gradient(40% 100%, circle, transparent 20%, #946947 35%);
    background-image: -ms-radial-gradient(40% 100%, circle, transparent 20%, #946947 35%);
    background-image: -o-radial-gradient(40% 100%, circle, transparent 20%, #946947 35%);
    background-image: radial-gradient(40% 100%, circle, transparent 20%, #946947 35%);
}

.notebook:before{
    position: absolute;
    content: '';
    top: -5px;
    left: 0;
    height: 10px;
    width: 99%;
    background-size: 9px 12px;
    background-image: -webkit-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: -moz-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: -ms-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: -o-radial-gradient(5% 40%, circle, transparent 70%, #555 20%);
    background-image: radial-gradient(circle at 5% 40%, transparent 70%, #555 20%);
}
.footer{
  font-size: 14px;
  text-align: right;
  /* background-color: #eee; */
  margin:10px 0px 10px 0px;
  padding: 15px;
  color:#ccc;
}
@media screen and (max-width: 768px) {
#mobile{
  display: block;
}
#bigscreen{
  display:none;
}
}
@media screen and (min-width: 768px) {
#mobile{
  display: none;
}
#bigscreen{
  display:block;
}
}